<script>
import api from '@/helpers/api-rest/api.js';

export default {
    props:{
        showDepartmentModal: Boolean,
        department: {type: Object, default: () => {
            return {
                name: '',
            }
        }}
    },
    data() {
        return {
        };
    },
    computed: {
        modalTitle() {
        return this.department?.id ? 'Edit Department' : 'Add Department';
        },
        submitButtonText() {
            return this.department?.id ? 'Save' : 'Add Department';
        },
    },
    methods: {
        createDepartment() {
            const apiEndpoint = `/api/v1/departments`; 
            api.post({url:apiEndpoint, data:this.department})
            //axios.post(apiEndpoint, this.user)
                .then(response => {
                    const newDepartment = response.data;
                    this.$emit('success', newDepartment);
                    this.$bvModal.hide("ModalDepartment");
                })
                .catch(error => {
                    console.error("Error al crear el departamento:", error);
                });
        },

        updateDepartment() {
            const apiEndpoint = `/api/v1/departments`;
            api.put({url:apiEndpoint, data:this.department, id:this.department.id})
            .then(response => {
                const updatedDepartment = response.data;
                this.$emit('success', updatedDepartment);
                this.$bvModal.hide("ModalDepartment");
            })
            .catch(error => {
                console.error("Error editing department:", error);
            });
        },

        submitForm() {
            if(this.department?.id){
                return this.updateDepartment();
            }
            return this.createDepartment();
        },
        cancelForm() {
            this.$bvModal.hide("ModalDepartment");
            this.$emit('close-modal');
        }
    }
}
</script>
<template>
    <b-modal id="ModalDepartment" :title="modalTitle">  
      <template #default="{  }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="form-group">
                <label for="name">Nombre:</label>
                <input v-model="department.name" type="text" class="form-control" id="name" required>
            </div>
        </form>
      </template>
  
      <template #modal-footer="{  }">
        <b-button variant="primary" @click="submitForm()">{{ submitButtonText }}</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
</template>
